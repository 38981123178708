<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Status">
      <b-form-radio-group
        v-model="statusFilterValue"
        :options="statusRadioOptions"
        name="status-radios-stacked"
        stacked
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BFormRadioGroup, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    BCol,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusRadioOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusFilterValue: this.statusFilter,
    }
  },
  watch: {
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = val
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.statusFilterValue = null
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
